$prospectColor: #F8CBAD;

.table-default.margin {
    th {
        font-weight: bold;
    }

    td.total {
        font-weight: bold;
    }

    tr.total {
        font-weight: bold;
        background-color: rgba(0,0,0,.02);
    }
}

.prospect {
    background-color: $prospectColor !important;
}

.prospectCheckbox label::before {
    background-color: $prospectColor !important;
}
