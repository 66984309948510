$prospectColor: #F8CBAD;
$extensionsColor: #C6E0B5;

.table-default.margin {
    th {
        font-weight: bold;
    }

    td.total {
        font-weight: bold;
    }

    tr.total {
        font-weight: bold;
    }
}

.prospect {
    background-color: $prospectColor !important;
}

.prospectCheckbox label::before {
    background-color: $prospectColor !important;
}

.extension {
    background-color: $extensionsColor !important;
}

.extensionsCheckbox label::before {
    background-color: $extensionsColor !important;
}
