.dropzonecontainer {
  border: 1px dashed #35405280;
  //background-color: rgba(255, 255, 255, 0.5);
  color: #4b5563;
  font-weight: bold;
  padding: 30px;
  border-radius: 4px;
  & p {
    text-align: center;
  }
  & i {
    margin: 0 auto 10px;
    width: 37px;
    display: block;
  }
}
