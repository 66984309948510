.login-page {
    display: flex;
    width: 100%;
    // height: 100%;
    flex: 1 0 auto;
    // overflow: scroll;

    h2 {
        font-weight: 500;
        size: 20px;
        line-height: 22px;
        color: #323232;
        font-size: 20px;
    }

    hr {
        width: 100%;
        background-color: #32323233;
        height: 1px;
        border: 0;
    }

}