.footer-component {
    height: 274px;
    background-color: #343B48;
    // bottom: 0;
    // position: absolute;
    width: 100%;
    // border: 1px solid green;

    flex-shrink: 0;
    white-space: pre-line;

    p {
        color: #FFFFFFCC;
        font-weight: 400;
        line-height: 24px;
        size: 14px;
        // white-space: pre-line;
    }

    .blue-line {
        background-color: #1E69F0;
        height:4px;
        width: 100%;
        position: relative;
    }

    h3 {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: rgba(255, 255, 255, 1);
        text-transform: uppercase;
    }

    h4 {
        font: {
            weight: 400;
            size: 14px;
        }
        line-height: 22px;
        margin-top: 0px;
        color: rgba(255, 255, 255, 1);
    }
}