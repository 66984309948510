.table-default {
    margin-left: 20px;
    margin-right: 20px;
    table {
        margin: 10px;
        display: table;
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
        text-align: left;

        thead {
            background: rgba(240, 240, 240, 0.31);
            tr {
                border: none;

                th {
                    border: none;
                    height: 40px;
                    padding-right: 14px;
                    font-weight: 400;
                    text-align: right;
                    &:first-child {
                        text-align: left;
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid #E7E7E7;
                &:last-child {
                    border: none;
                }
                td {
                    padding: 10px 10px 10px 14px;
                    text-align: right;
                    &:first-child {
                        text-align: left;
                    }
                }
            }
        }
    }
}