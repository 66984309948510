.App {
  /* text-align: center; */
  //  min-height: 100vh;
  display: flex;
  flex-direction: column;
  //height: 100vh;
  background-color: rgb(250, 250, 250);

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.datepicker input {
  width: 100% !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 1400px) {
 .ui.container {
   width: 1400px;
 }

 .ui.grid.container {
   width: calc( 1400px  +  3rem ) !important;
 }

 .ui.relaxed.grid.container {
   width: calc( 1400px  +  4rem ) !important;
 }

 .ui.very.relaxed.grid.container {
   width: calc( 1400px  +  6rem ) !important;
 }
}

.status_chip {
  font-weight: 600 !important;
  border: 0 none !important;

  span {
    padding: 0 !important;
  }

  &.status_chip_active {
    color: #508450;
  }

  &.status_chip_inactive {
    color: #858585;
  }

  &.status_chip_archive {
    color: #344646;
  }

  &.status_chip_prospect {
    color: #dd7c0c;
  }
}

#root, .App {
  min-height: 100vh;
}

.backBtn {
  display: block;
  margin: 8px 14px;
  cursor: pointer;

  svg {
    float: left;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    --webkit-appearance: none !important;
    margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

