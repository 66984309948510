$forecastColor: #f0e7c2;

.total-row {
    font-weight: bold;   
}

.forecast {
    background-color: $forecastColor;
}

.forecastCheckbox label::before {
    background-color: $forecastColor !important;
}

.MuiTooltip-tooltip {
    font-size: 12px !important;
}