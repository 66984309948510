.header-component {
    width: 100%;
    // position: absolute;
    top: 0;
    height: 150px;
    // margin-bottom: 8px;
    margin-bottom: 58px;

    .blue-line {
        background-color: #1E69F0;
        height:8px;
        width: 100%;
    }

    p {
        // color: #323232;
        color: "rgba(50, 50, 50, 1)";
        font-weight: 400;
        line-height: 24px;
        size: 14px;
        white-space: pre-line;
    }
}
